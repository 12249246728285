import { useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import { ChatProps } from './props';
import UploadFileInput from './components/UploadFileInput';
import { AvatarResponse } from '../../../models/avatar';
import LoadingMessage from './components/LoadingMessage';
import FileDescriptionInput from './components/FileDescriptionInput';
import LoadingSpinner from '../LoadingSpinner';
import UploadFileSendBtn from './components/UploadFileSendBtn';
import { ChatContextModel } from '../../../context/Chat/props';
import { ChatContext } from '../../../context/Chat/Chat';
import UploadFileCancelBtn from './components/UploadFileCancelBtn';
import FileIcon from './components/FileIcon';
import { createEventListeners } from '../../../helpers/button';
import { useAvatarContext } from '../../../context/Avatar/AvatarContext';

const Chat = ({ messages, isBotThinking }: ChatProps) => {
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const [fileDescription, setFileDescription] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { talk } = useAvatarContext();

  useEffect(() => {
    messagesContainerRef.current.scrollTo({
      behavior: 'smooth',
      top: messagesContainerRef.current.scrollHeight,
    });
    createEventListeners(talk);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const renderMessageByType = (response: AvatarResponse) => {
    switch (response?.formato_respuesta_usuario?.toLowerCase()) {
      case 'filetoshow':
        return (
          <>
            {loading ? (
              <div className="row-h-center">
                <LoadingSpinner />
              </div>
            ) : (
              <>
                {response.file && (
                  <>
                    <FileIcon
                      fileName={response.file.name}
                      fileType={response.file.type}
                    />
                    <br />
                  </>
                )}
                {response.respuesta_usuario}
                {response.file && (
                  <>
                    <br />
                    <div>
                      <FileDescriptionInput />
                      <div className="row-h-center">
                        <UploadFileCancelBtn message={response} />
                        <UploadFileSendBtn message={response} />
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        );
      case 'html':
        return (
          <span className="message-html">
            {parse(`${response.respuesta_usuario}`)}
          </span>
        );
      case 'img':
        return (
          <>
            {response.respuesta_usuario}
            <br />
            <img
              src={response.url_img}
              alt="img"
              className="message-img light-shadow"
            />
          </>
        );
      default:
        // plaintext
        return response?.respuesta_usuario;
    }
  };

  const renderFileInput = (response: AvatarResponse) => {
    if (response?.archivo_upload === 'yes') {
      return (
        <>
          <br />
          <UploadFileInput idMessage={response.id} />
        </>
      );
    }
  };

  const renderMessageWidgets = (time: string, dominio: string) => (
    <div className="message-time">
      <span className="message-type">{dominio}</span>
      {time}
    </div>
  );

  const context: ChatContextModel = {
    setLoading,
    fileDescription,
    setFileDescription,
  };

  return (
    <ChatContext.Provider value={context}>
      <div ref={messagesContainerRef} className="messages column">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.sender} light-shadow`}>
            <b>{message.author}: </b>
            {renderMessageByType(message.response)}
            {renderFileInput(message.response)}
            {renderMessageWidgets(
              message.response?.time,
              message.response?.dominio
            )}
          </div>
        ))}
        <LoadingMessage isBotThinking={isBotThinking} />
      </div>
    </ChatContext.Provider>
  );
};

export default Chat;
