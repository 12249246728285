import AvatarApp from './ui/screens/AvatarApp';
import { MsalProvider } from '@azure/msal-react';
import msalConfig from './auth/config';
import Toast from './ui/components/Toast';

const App = () => {
  return (
    <MsalProvider instance={msalConfig}>
      <Toast>
        <AvatarApp />
      </Toast>
    </MsalProvider>
  );
};

export default App;
