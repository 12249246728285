import endpoints from './endpoints.json';
import { apiUrl, avatarType, usuario } from '../../constants';
import { AvatarConfiguration, AvatarResponse } from '../../models/avatar';
import { User } from '../../models/auth';
import { toMessageFormat } from '../../models/chat';

const getGptData = async (
  userMessage: string,
  userToken: string,
  user: User
): Promise<AvatarResponse | undefined> => {
  const data: AvatarResponse = toMessageFormat(
    'No se pudo recibir una respuesta válida.'
  );
  try {
    const response = await fetch(
      `${apiUrl}${endpoints.get.gpt}?code=${process.env.REACT_APP_GPT_CODE}&session_id=${user?.email}&mensaje=${userMessage}&usuario=${user?.name}`,
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
      }
    );
    if (!response.ok) {
      switch (response.status) {
        // TODO: ver en qué casos no se recibe una respuesta con el formato correcto para hacer el .json() y controlarlo en este switch
        case 401:
          data.respuesta_usuario = 'Usuario no autorizado.';
          return data;
        default:
          break;
      }
    }
    const dataResponse: AvatarResponse = await response.json();
    console.log('gpt response', dataResponse);
    return dataResponse;
  } catch (error) {
    console.log('gpt catch error', error);
    return data;
  }
};

const getVariableData = async (
  userMessage: string,
  streamId: string
): Promise<string | undefined> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CEU_API}${endpoints.get.customData}?code=${process.env.REACT_APP_CONFIG_CODE}&session_id=${streamId}&mensaje=${userMessage}&usuario=${usuario}&avatar=${avatarType}`
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.text();
    return data;
  } catch (error) {
    console.log('variable data', error);
  }
};

const getInitialConfig = async (): Promise<AvatarConfiguration | undefined> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CEU_API}${endpoints.get.avatarConfiguration}?code=${process.env.REACT_APP_CONFIG_CODE}&avatar_type=${avatarType}`
    );
    if (response.ok && response.status === 200) {
      const jsonResponse: AvatarConfiguration = await response?.json();
      if (jsonResponse) {
        return jsonResponse;
      } else {
        console.error(`Configuración inicial sin datos`, jsonResponse);
      }
    }
  } catch (error) {
    console.error(`Error con el avatar ${avatarType}`, error);
  }
};

const uploadFile = async (
  file: File,
  userEmail: string,
  userMessage: string
) => {
  try {
    const postData = new FormData();
    postData.append('pdf', file);
    const response = await fetch(
      `${apiUrl}${endpoints.post.uploadFile}?code=${process.env.REACT_APP_UPLOAD_FILE_CODE}&user_mail=${userEmail}&mensaje=${userMessage}`,
      {
        method: 'POST',
        body: postData,
      }
    );
    if (response.ok && response.status === 200) {
      const data = await response?.json();
      if (data) {
        return data;
      }
    } else {
      console.error('Fallo al subir fichero');
      return false;
    }
  } catch (error) {
    console.error('Fallo al subir fichero -->', error);
    return false;
  }
};

export { getInitialConfig, getGptData, getVariableData, uploadFile };
