import { BrowserCacheLocation, Configuration } from '@azure/msal-browser';

export const configuration: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_TENANT_ID!!,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: process.env.REACT_APP_LOGIN_REDIRECT,
    postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_REDIRECT,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
  },
};

export const scopes: string[] = [process.env.REACT_APP_USER_SCOPES!!];
