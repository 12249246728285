const removeItself = (element: HTMLElement) => {
  element.parentElement.removeChild(element);
};

export const createEventListeners = (
  talk: (userMessage: string, initialGreeting?: boolean) => void
) => {
  const tcBtn = document.getElementById('btcondiciones');
  if (tcBtn && !tcBtn?.onclick) {
    tcBtn.onclick = () => {
      talk('Acepto los términos y condiciones.');
      removeItself(tcBtn);
    };
  }
};
